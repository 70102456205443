<template>
  <v-row>
    <!-- Start Single Portfolio  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      class="mt--40"
      v-for="(item, i) in portfolioContent"
      :key="i"
    >
      <div class="portfolio">
        <div class="thumbnail-inner">
          <v-img class="thumbnail" :src="item.src"></v-img>
          <v-img class="bg-blr-image" :src="item.src"></v-img>
        </div>
        <div class="content">
          <div class="inner">
            <p>{{ item.meta }}</p>
            <h4>
              <router-link to="portfolio-details">{{ item.title }}</router-link>
            </h4>
            <div style="color: white">
              {{item.infos}}
            </div>
          </div>
        </div>
      </div>
    </v-col>
    <!-- End Single Portfolio  -->
  </v-row>
</template>

<script>
  export default {
    data() {
      return {
        portfolioContent: [
          {
            src: require("../../assets/images/portfolio/projects/homestate.png"),
            meta: "Development",
            title: "homestate.eu",
            infos: "since August 2023"
          },
          {
            src: require("../../assets/images/portfolio/projects/dirty.png"),
            meta: "Owner & Development",
            title: "Dirty-Gaming.com",
            infos: "Developer Juni 2020 | Owner Oktober 2020 - 02.07.2023"
          },
          {
            src: require("../../assets/images/portfolio/projects/altv.png"),
            meta: "Quality Assurance & Moderator",
            title: "alt:V",
            infos: "QA seit november 2020 | Moderator seit Mai 2021"
          },
          {
            src: require("../../assets/images/portfolio/projects/unity.png"),
            meta: "Administrator & Development",
            title: "Unity-Life.de",
            infos: "Juni 2018 (Arma3) - Mai 2020 (GTA 5)"
          },
          {
            src: require("../../assets/images/portfolio/projects/projectnine.png"),
            meta: "Development-Lead",
            title: "ProjectNine.de",
            infos: "September 2017 - Juni 2018"
          },
          {
            src: require("../../assets/images/portfolio/projects/ruhrpott.png"),
            meta: "Development",
            title: "Ruhrpott-Life & Ruhrpott-Life v2",
            infos: "Juni 2017 - September 2017 (v1) | Januar 2019 - April 2019 (v2)"
          },
          {
            src: require("../../assets/images/portfolio/projects/infistar.png"),
            meta: "Support",
            title: "infistar.de"
          },
          {
            src: require("../../assets/images/portfolio/projects/bytex.png"),
            meta: "Support",
            title: "Maverick-Applications / bytex.market",
          },
          {
            src: require("../../assets/images/portfolio/projects/armaservices.png"),
            meta: "Support & Manager",
            title: "Armaservices.com",
            infos: "Support (2018 - end of 2021) | Manager (2020 - end of 2021)"
          },
          {
            src: require("../../assets/images/portfolio/projects/epvp.png"),
            meta: "Moderator & Support",
            title: "elitepvpers.com",
            infos: "2015 - 2020"
          },
        ],
      };
    },
  };
</script>
